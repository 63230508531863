import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import styles from './styles/qualityStyles';
import { useAboutQuery } from '../../app/queries/about.query';

const PATH = 'about';

function QualityCard({ image, name, description }) {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { aboutImgs } = useAboutQuery();
  const imgPath = image ? aboutImgs[`${PATH}/${image}`] : 'null';

  return (
    <Box sx={styles.container}>
      <Box sx={styles.imageWrapper}>
        {image ? (
          <GatsbyImage
            className="image-wrapper"
            image={getImage(imgPath)}
            alt={name}
            loading="lazy"
          />
        ) : (
          <StaticImage src="./images/frame.png" alt={name} />
        )}
      </Box>
      <Typography variant="desktopH4" sx={styles.name}>
        {name}
      </Typography>
      <Typography
        variant={isSm ? 'mobileP1' : 'desktopP2'}
        sx={styles.description}
      >
        {description}
      </Typography>
    </Box>
  );
}

export default QualityCard;
