const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '24px 32px 32px',
    gap: '0px',
    borderRadius: '40px',
  },
  imageWrapper: (theme) => ({
    width: '100%',
    maxWidth: '168px',
    height: '168px',
    lineHeigth: 0,
    borderRadius: '16px',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100px',
      height: '100px',
    },
  }),
  name: {
    margin: '24px 0 16px',
  },
  description: {
    minHeight: '77px',
  },
};

export default styles;
