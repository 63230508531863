import React from 'react';
import About from '../widgets/About/About';
import Layout from '../shared/Layout/Layout';
import HeadMaker from '../HeadMaker';
import { QuestionsInContent } from '../widgets';
import data from '../pages-fsd/AboutPage/model/about.data';
import { useAboutQuery } from '../app/queries/about.query';

export function Head(props) {
  return <HeadMaker {...props} seo={data.seo} />;
}

const mainData = {
  leadPage: 'Бесплатные мероприятия',
};

const leadData = {
  question: {
    ...mainData,
    eventName: 'Подпишитесь на нашу рассылку',
    leadAction: 'подписаться',
    leadBlock: 'Анонсы событий',
  },
};

function AboutPage() {
  const { aboutImgs } = useAboutQuery();

  return (
    <Layout leadPage="About">
      {data.content.map((block, index) => (
        <About key={index} {...block} aboutImgs={aboutImgs} />
      ))}
      <QuestionsInContent
        {...data.questions.actions}
        extraLeadData={leadData.question}
      />
    </Layout>
  );
}

export default AboutPage;
