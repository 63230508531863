import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Box } from '@mui/material';
import styles from './styles';

function ImageItem({ path, alt = '', loading = 'auto' }) {
  return (
    <Box sx={styles}>
      <GatsbyImage
        className="image-wrapper"
        image={getImage(path)}
        alt={alt}
        loading={loading}
      />
    </Box>
  );
}

export default ImageItem;
