const styles = {
  height: '100%',
  width: '100%',
  '.image-wrapper': {
    height: '100%',
    width: '100%',
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
};

export default styles;
