import React from 'react';
import { Box, Typography } from '@mui/material';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import styles from './styles/teamStyles';

function TeamCard({ name, position, image, path }) {
  return (
    <Box>
      <Box sx={styles.imageWrapper}>
        {image ? (
          <GatsbyImage
            className="image-wrapper"
            image={getImage(path)}
            alt={name}
            loading="lazy"
          />
        ) : (
          <StaticImage src="./images/frame.png" alt={position} />
        )}
      </Box>
      <Typography variant="desktopH5" sx={styles.name}>
        {name}
      </Typography>
      <Typography variant="desktopP2" color="kit.text.main">
        {position.split(' ').map((word, index) => (
          <span
            key={index}
            style={{
              display: 'inline-block',
              minWidth: '100%',
              whiteSpace: 'nowrap',
            }}
          >
            {word}
          </span>
        ))}
      </Typography>
    </Box>
  );
}

export default TeamCard;
