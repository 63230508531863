const styles = {
  imageWrapper: {
    width: '100%',
    maxWidth: '200px',
    lineHeigth: 0,
    marginBottom: '16px',
  },
  name: {
    color: 'kit.text.h',
    marginBottom: '8px',
  },
};

export default styles;
