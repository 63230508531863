const lineStyles = {
  display: {
    xs: 'block',
    sm: 'none',
  },
  position: 'absolute',
  width: '1px',
  height: '100%',
  backgroundColor: 'kit.background.purple',
  left: 0,
  top: 0,
};

const styles = {
  container: {
    paddingBottom: '40px',
  },
  card: {
    position: 'relative',
    flexShrink: 0,
    width: '200px',
    paddingBottom: {
      xs: '0',
      sm: '40px',
    },
    display: 'block',
    '&.isInfo': {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 0,
      paddingLeft: {
        xs: '0',
        sm: '24px',
      },
      maxWidth: '328px',
      width: {
        xs: '100%',
        sm: '200px',
      },
      '&::before': {
        content: '""',
        ...lineStyles,
        display: {
          xs: 'none',
          sm: 'block',
        },
      },
    },
    '&.isRemainder': {
      display: {
        xs: 'block',
        sm: 'none',
      },
    },
  },
  description: {
    color: 'kit.text.main',
    marginBottom: '24px',
    fontSize: {
      sm: '14px',
    },
    lineHeight: {
      sm: '20px',
    },
  },
  bottomDescription: {
    color: 'kit.text.main',
    marginTop: 'auto',
    fontSize: {
      sm: '14px',
    },
    lineHeight: {
      sm: '20px',
    },
  },
  statisticValue: {
    fontSize: 60,
    lineHeight: 1.2,
    color: 'kit.text.h',
    fontWeight: 700,
    marginBottom: '4px',
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: {
      md: 'flex-end',
    },
    position: 'relative',
    paddingLeft: {
      xs: '40px',
      md: '0',
    },
    marginTop: {
      xs: '68px',
      sm: '0',
    },
    '&.isInfoWrapper:not(.isRemainderWrapper)': {
      marginTop: {
        xs: '47px',
        sm: '0',
      },
      '&+div': {
        marginTop: {
          xs: '24px',
          sm: '0',
        },
      },
    },
    '&:first-child': {
      marginTop: '0 !important',
    },
    marginBottom: {
      xs: '0',
      sm: '40px',
    },
    '&::before': {
      content: '""',
      ...lineStyles,
    },
    '&:nth-child(odd)': {
      '&::before': {
        display: {
          sm: 'block',
          md: 'none',
        },
      },
    },
    '&:nth-child(4n-3)': {
      '&::before': {
        display: {
          md: 'block',
        },
      },
    },
  },
  buttonBlock: {
    paddingTop: '32px',
    display: 'flex',
    alingItems: 'center',
    justifyContent: 'center',
  },
  selectWrapper: {
    paddingLeft: {
      xs: '0',
      sm: '40px',
      md: '0',
    },
    display: {
      xs: 'block',
      md: 'flex',
    },
  },
  select: {
    marginLeft: 'auto',
    marginBottom: {
      xs: '32px',
      sm: '40px',
    },
    width: {
      xs: '100%',
      sm: '200px',
    },
  },
};

export default styles;
