import React, { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { StaticImage } from 'gatsby-plugin-image';
import styles from './styles';

const sideStyles = (leftSide) => ({
  transform: `translateY(30px) translateX(${leftSide ? '-100%' : '100%'})`,
  '&.active': {
    opacity: 1,
    transform: `translateY(0px) translateX(${leftSide ? '-100%' : '100%'})`,
  },
});

const createIcon = (icon) => {
  switch (icon) {
    case 'purple-heart': {
      return (
        <StaticImage
          className="icon"
          src="./images/purple-heart.png"
          alt="heart"
        />
      );
    }
  }
};

function AnimatedMessage({
  message,
  customStyles,
  isOnTheLeft,
  isOnTheLeftMobile,
  icon,
  delay,
}) {
  const isLg = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const leftSide = (isOnTheLeftMobile && isLg) || isOnTheLeft;

  const { ref, inView } = useInView({
    threshold: 1,
    delay: delay || 0,
  });
  return (
    <Box key={message} ref={ref} sx={styles.wrapper}>
      <Box
        sx={{ ...styles.message, ...customStyles, ...sideStyles(leftSide) }}
        className={`${inView && 'active'} ${leftSide && 'isOnTheLeft'}`}
      >
        {leftSide ? (
          <StaticImage
            src="./backgrounds/left-bubble.png"
            alt=""
            objectFit="unset"
            className="bubble"
          />
        ) : (
          <StaticImage
            src="./backgrounds/right-bubble.png"
            alt=""
            objectFit="unset"
            className="bubble"
          />
        )}
        <Typography
          component={'div'}
          variant="desktopP3Helper"
          sx={styles.text}
          className="text"
        >
          <Typography component="span">
            {message}
            &nbsp;
          </Typography>
          {!!icon && createIcon(icon)}
        </Typography>
      </Box>
    </Box>
  );
}

export default AnimatedMessage;
