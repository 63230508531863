const styles = {
  wrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',

    '& .icon': {
      width: {
        xs: '12px',
        sm: '16px',
      },
      height: {
        xs: '12px',
        sm: '16px',
      },
    },
  },
  message: {
    position: 'absolute',
    padding: {
      xs: '8px',
      sm: '12px',
      md: '14px',
    },
    paddingLeft: {
      xs: '12px',
      sm: '19px',
      md: '24px',
    },
    '&.isOnTheLeft': {
      paddingLeft: {
        xs: '8px',
        sm: '12px',
        md: '14px',
      },
      paddingRight: {
        xs: '12px',
        sm: '19px',
        md: '24px',
      },
    },
    zIndex: 1,
    transition: '.6s transform, .6s opacity',
    opacity: 0,
    '&.active': {
      opacity: 1,
    },
    '& .bubble': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 1,
      img: {
        width: '100%',
        height: '100%',
      },
    },
  },
  text: {
    position: 'relative',
    color: 'kit.text.main',
    zIndex: 2,
    fontSize: {
      xs: '10px',
      sm: '12px',
    },
    lineHeight: {
      xs: '13px',
      sm: '15px',
    },
  },
};

export default styles;
