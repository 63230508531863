const imageStyles = {
  position: 'relative',
  borderRadius: '8px',
  overflow: 'hidden',
  lineHeight: 0,
};

const styles = {
  team: {
    paddingBottom: {
      xl: '40px',
    },
  },
  statistic: {
    justifyContent: 'flex-end',
    paddingBottom: {
      xs: '32px',
      md: '62px',
    },
  },
  video: {
    '& .preview': {
      borderRadius: '8px',
      overflow: 'hidden',
      img: {
        top: {
          xs: 0,
          sm: '50%',
        },
        transform: {
          sm: 'translateY(-50%)',
        },
      },
      '& > div': {
        minHeight: '100%',
      },
    },
  },
  videoBlock: {
    marginBottom: {
      xs: '23px',
      md: '114px',
    },
  },
  mainTitle: {
    color: 'kit.text.main',
    textTransform: 'uppercase',
    fontSize: {
      xs: '46px',
      md: '100px',
    },
    lineHeight: {
      xs: '1.2',
      sm: '1.1',
    },
    marginBottom: {
      xs: '24px',
      sm: '32px',
    },
  },
  title: {
    color: 'kit.text.main',
    marginBottom: {
      xs: '24px',
      md: '32px',
    },
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '-40px',
    left: 0,
    display: {
      xs: 'none',
      xl: 'block',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
  },
  contentItemWrapper: {
    '& .contentItem': {
      paddingLeft: '40px',
      position: 'relative',
      '&::before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '1px',
        backgroundColor: 'kit.text.h',
      },
    },
    '& .text': {
      marginLeft: {
        sm: 'auto',
      },
    },
    '&:nth-child(even)': {
      '& .contentItem': {
        paddingLeft: {
          md: 0,
        },
        '&::before': {
          display: {
            md: 'none',
          },
        },
      },
      '& .text': {
        marginLeft: 0,
        marginRight: {
          sm: 'auto',
        },
      },
    },
    '&:nth-child(odd)': {
      '.text': {
        '&::before': {
          content: '""',
          display: 'inline-block',
          width: {
            md: '112px',
          },
        },
      },
    },
    '&.isMobileHidden': {
      display: {
        xs: 'none',
        md: 'block',
      },
    },
  },
  text: {
    maxWidth: {
      md: '536px',
    },
  },
  mainImageWrapper: {
    height: {
      xs: '355px',
      md: '557px',
    },
    marginBottom: {
      xs: '24px',
      md: '40px',
    },
    ...imageStyles,
  },
  imageWrapper: {
    height: '355px',
    marginBottom: {
      xs: '32px',
      md: '62px',
    },
    ...imageStyles,
  },
  qualityBlockWrapper: {
    color: 'kit.text.main',
  },
  qualityTitle: {
    whiteSpace: 'pre-line',
    margin: {
      md: '40px 0 32px',
      xs: '40px 0 24px',
    },
  },
  qualityItemContainer: {
    display: 'flex',
    flexDirection: {
      md: 'row',
      xs: 'column',
    },
    alignItems: 'flex-start',
    padding: '0',
    gap: '16px',
  },
  qualityItem: {
    backgroundColor: 'kit.background.darkgray',
    borderRadius: '40px',
    maxWidth: '424px',
    width: {
      md: '100%',
      sm: '75%',
      sx: '100%',
    },
  },
};

export default styles;
