import { useStaticQuery, graphql } from 'gatsby';
import { getImageDataMapByFileEdges } from '../../helpers/lib';

export const useTeamQuery = () => {
  const { allFile } = useStaticQuery(graphql`
    query teamQuery {
      allFile(filter: { relativeDirectory: { regex: "/team/" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            relativeDirectory
            name
            absolutePath
          }
        }
      }
    }
  `);

  const teamImgs = getImageDataMapByFileEdges(allFile.edges);
  return { teamImgs };
};
