import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, useMediaQuery } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import VideoBlock from '../../shared/VideoBlock/VideoBlock';
import Section from '../../shared/Section/Section';
import ImageItem from '../../shared/ImageItem/ImageItem';
import Team from '../../shared/Team/Team';
import StatisticItem from '../../shared/Statistic/StatisticItem';
import AnimatedMessage from '../../shared/AnimatedMessage/AnimatedMessage';
import { getStats } from '../../api/strapi';
import CustomCarousel from '../../shared/CustomCarousel/CustomCarousel';
import styles from './styles';
import QualityCard from '../../shared/Cards/QualityCard';

const PATH = 'about';

function About({
  aboutImgs,
  bg,
  mainTitle,
  title,
  video,
  mainPhoto,
  items,
  qualitiesTitle,
  qualitiesList,
  statistic,
  slides,
  message,
  type,
}) {
  const [info, setInfo] = useState(
    statistic ? new Array(statistic.length).fill({}) : null,
  );
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isMd = useMediaQuery((theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    if (statistic) {
      const ac = new AbortController();
      const getData = async () => {
        try {
          const statData = await getStats();
          setInfo(() =>
            statistic.map((item) => ({
              ...item,
              value: statData.find((el) => el.id === item.id)?.statTitle,
              description: statData.find((el) => el.id === item.id)
                ?.statSubTitle,
            })),
          );
        } catch (e) {
          console.log(e);
        }
      };
      getData();
      return () => {
        ac.abort();
      };
    }
  }, [statistic]);

  const generateImagePath = (image) =>
    `${PATH}/${image}-${isSm ? 'mobile' : 'desktop'}`;

  return (
    <Section customStyles={type === 'team' ? styles.team : {}}>
      {!!bg && (
        <Box sx={styles.background}>
          <StaticImage src="./images/about.png" alt="elbrus bootcamp" />
        </Box>
      )}

      {!!statistic && (
        <Grid
          container
          sx={styles.statistic}
          spacing={{
            xs: '20px',
            md: '24px',
          }}
        >
          {info.map((item, index) => (
            <Grid item key={index} xs={12} md={2}>
              <StatisticItem {...item} />
            </Grid>
          ))}
        </Grid>
      )}

      {!!mainTitle && (
        <Typography variant="desktopH1" sx={styles.mainTitle}>
          {mainTitle}
        </Typography>
      )}

      {!!title && (
        <Typography variant="desktopH2" sx={styles.title}>
          {title}
        </Typography>
      )}
      {!!video && (
        <Box sx={styles.videoBlock}>
          <VideoBlock
            customStyles={styles.video}
            video={video.src}
            description={video.description}
          >
            {isMd ? (
              <StaticImage
                src="../../shared/VideoBlock/images/mobile.png"
                alt="preview"
                placeholder="blurred"
                layout="constrained"
                style={{ objectFit: 'contain' }}
              />
            ) : (
              <StaticImage
                src="../../shared/VideoBlock/images/dodPreview.png"
                alt="preview"
              />
            )}
          </VideoBlock>
        </Box>
      )}

      {!!mainPhoto && (
        <Box sx={styles.mainImageWrapper} className="mainPhoto">
          {!!mainPhoto.message && (
            <AnimatedMessage
              message={mainPhoto.message}
              customStyles={mainPhoto.messageStyles}
              isOnTheLeft={mainPhoto.isOnTheLeft}
              isOnTheLeftMobile={mainPhoto.isOnTheLeftMobile}
              icon={mainPhoto.icon}
              delay={mainPhoto.delay}
            />
          )}
          <ImageItem path={aboutImgs[generateImagePath(mainPhoto.image)]} />
        </Box>
      )}

      {type === 'team' && <Team team={items} message={message} />}

      {type !== 'team' && !!items && (
        <Grid
          container
          spacing={{
            xs: '24px',
            sm: '40px',
          }}
        >
          {items.map((item, index) => (
            <Grid
              item
              key={index}
              xs={12}
              md={6}
              sx={styles.contentItemWrapper}
              className={item.isMobileHidden ? 'isMobileHidden' : ''}
            >
              {!!item.image && (
                <Box sx={styles.imageWrapper}>
                  {!!item.message && (
                    <AnimatedMessage
                      message={item.message}
                      customStyles={item.messageStyles}
                      isOnTheLeft={item.isOnTheLeft}
                      isOnTheLeftMobile={item.isOnTheLeftMobile}
                      icon={item.icon}
                    />
                  )}
                  <ImageItem path={aboutImgs[generateImagePath(item.image)]} />
                </Box>
              )}
              <Box className="contentItem">
                <Typography
                  variant="desktopP1"
                  sx={{ ...styles.text, ...item.textStyles }}
                  className="text"
                >
                  {item.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
      {type === 'qualities' && (
        <Box sx={styles.qualityBlockWrapper}>
          <Typography variant="desktopH3" sx={styles.qualityTitle}>
            {qualitiesTitle}
          </Typography>
          {qualitiesList && (
            <Box container sx={styles.qualityItemContainer}>
              {qualitiesList.map((quality, index) => (
                <Box item key={index} sx={styles.qualityItem}>
                  <QualityCard {...quality} />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
      {type === 'slider' && (
        <CustomCarousel
          slides={slides.map((slide) => ({
            path: aboutImgs[`${PATH}/${slide.image}`],
          }))}
        />
      )}
    </Section>
  );
}

export default About;
